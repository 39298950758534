var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    class: _vm.device + "-event",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("投资者关系")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("INVESTORS")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("投資者關係")]) : _vm._e()]), _c("div", {
    staticClass: "companyInfo clearfix"
  }, [_c("ul", {
    staticClass: "tMenuLeft",
    class: _vm.language == 2 ? "tMenuLeftEn" : ""
  }, [_vm.language == 1 || !_vm.language ? _c("div", _vm._l(_vm.irMenuData, function (items, index) {
    return _c("router-link", {
      key: index,
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.name)), _c("em", [_vm._v(">")])])]);
  }), 1) : _vm._e(), _vm.language == 2 ? _c("div", _vm._l(_vm.irMenuData, function (items, index) {
    return _c("router-link", {
      key: index,
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameEn)), _c("em", [_vm._v(">")])])]);
  }), 1) : _vm._e(), _vm.language == 3 ? _c("div", _vm._l(_vm.irMenuData, function (items, index) {
    return _c("router-link", {
      key: index,
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameTr)), _c("em", [_vm._v(">")])])]);
  }), 1) : _vm._e()]), _c("div", {
    staticClass: "comContentRight",
    class: _vm.language == 2 ? "comContentRightEn" : ""
  }, [_c("div", {
    staticClass: "searchBox"
  }, [_c("h4", [_vm._v(_vm._s(_vm.mapText.recentActivity[parseInt(_vm.language) - 1]))]), _vm.recentActivity.length == 0 ? _c("p", {
    staticClass: "no-result"
  }, [_vm._v(_vm._s(_vm.mapText.noActivity[parseInt(_vm.language) - 1]))]) : _vm._e(), _vm._l(_vm.recentActivity, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "recent-activity"
    }, [_c("span", [_vm._v(_vm._s(item.eventTitle))]), _c("span", {
      staticClass: "send-time"
    }, [_vm._v(_vm._s(item.sendTime))])]);
  }), _c("h4", [_vm._v(_vm._s(_vm.mapText.pastActivity[parseInt(_vm.language) - 1]))]), _vm.pastActivity.length == 0 ? _c("p", {
    staticClass: "no-result"
  }, [_vm._v(_vm._s(_vm.mapText.noActivity[parseInt(_vm.language) - 1]))]) : _vm._e(), _c("div", _vm._l(_vm.pastActivity, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "event-bg",
      class: {
        "deep-bg": index % 2 == 0
      }
    }, [_c("div", {
      staticClass: "layout-left"
    }, [_c("p", {
      staticClass: "activity-title",
      on: {
        click: function ($event) {
          return _vm.toDetail("/eventDetail", item);
        }
      }
    }, [_vm._v(_vm._s(_vm.language == 1 ? item.eventTitle : _vm.language == 2 ? item.eventTitleEn : item.eventTitleTrad))]), item.eventLink && index % 2 == 0 ? _c("div", {
      staticClass: "video-play",
      on: {
        click: function ($event) {
          return _vm.toDetail("/eventDetail", item);
        }
      }
    }, [item.eventLink.indexOf("MP3") < 0 ? _c("div", {
      staticClass: "flex"
    }, [_c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/video_white.svg"),
        alt: ""
      }
    }), _c("span", [_vm._v(_vm._s(_vm.mapText.watchVideo[parseInt(_vm.language) - 1]))])]) : _vm._e(), item.eventLink.indexOf("MP3") > -1 ? _c("div", {
      staticClass: "flex"
    }, [_c("img", {
      staticClass: "icon-svg audio",
      attrs: {
        src: require("../assets/icon/audio_white.svg"),
        alt: ""
      }
    }), _c("span", [_vm._v(_vm._s(_vm.mapText.listenMP3[parseInt(_vm.language) - 1]))])]) : _vm._e()]) : _vm._e(), item.eventLink && index % 2 != 0 ? _c("div", {
      staticClass: "video-play",
      on: {
        click: function ($event) {
          return _vm.toDetail("/eventDetail", item);
        }
      }
    }, [item.eventLink.indexOf("MP3") < 0 ? _c("div", {
      staticClass: "flex"
    }, [_c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/video_green.svg"),
        alt: ""
      }
    }), _c("span", [_vm._v(_vm._s(_vm.mapText.watchVideo[parseInt(_vm.language) - 1]))])]) : _vm._e(), item.eventLink.indexOf("MP3") > -1 ? _c("div", {
      staticClass: "flex"
    }, [_c("img", {
      staticClass: "icon-svg audio",
      attrs: {
        src: require("../assets/icon/audio_green.svg"),
        alt: ""
      }
    }), _c("span", [_vm._v(_vm._s(_vm.mapText.listenMP3[parseInt(_vm.language) - 1]))])]) : _vm._e()]) : _vm._e(), _c("div", {
      staticStyle: {
        display: "flex",
        "margin-top": "10px"
      }
    }, [item.eventPdfLinkEn && _vm.language == 2 && item.eventPdfLinkEn != "/investpdf/.pdf" ? _c("div", {
      staticClass: "pdf",
      staticStyle: {
        "margin-right": "40px"
      }
    }, [index % 2 != 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_green.svg"),
        alt: ""
      }
    }) : _vm._e(), index % 2 == 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_white.svg"),
        alt: ""
      }
    }) : _vm._e(), item.eventPdfLinkEn && item.iid != 41 ? _c("span", {
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.eventPdfLinkEn);
        }
      }
    }, [_vm._v("English")]) : _vm._e(), item.eventPdfLinkEn && item.iid == 41 ? _c("span", {
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.eventPdfLinkEn);
        }
      }
    }, [_vm._v("View Slides")]) : _vm._e()]) : _vm._e(), item.eventPdfLink && (_vm.language == 1 || _vm.language == 2) && item.eventPdfLink != "/investpdf/.pdf" ? _c("div", {
      staticClass: "pdf",
      staticStyle: {
        "margin-right": "40px"
      }
    }, [index % 2 != 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_green.svg"),
        alt: ""
      }
    }) : _vm._e(), index % 2 == 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_white.svg"),
        alt: ""
      }
    }) : _vm._e(), item.eventPdfLink ? _c("span", {
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.eventPdfLink);
        }
      }
    }, [_vm._v(_vm._s(_vm.language == 2 ? "Chinese" : "中文"))]) : _vm._e()]) : _vm._e(), item.eventPdfLinkTrad && _vm.language == 3 && item.eventPdfLinkTrad != "/investpdf/.pdf" ? _c("div", {
      staticClass: "pdf",
      staticStyle: {
        "margin-right": "40px"
      }
    }, [index % 2 != 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_green.svg"),
        alt: ""
      }
    }) : _vm._e(), index % 2 == 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_white.svg"),
        alt: ""
      }
    }) : _vm._e(), item.eventPdfLinkTrad ? _c("span", {
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.eventPdfLinkTrad);
        }
      }
    }, [_vm._v("中文")]) : _vm._e()]) : _vm._e(), item.eventPdfLinkEn && _vm.language != 2 && item.eventPdfLinkEn != "/investpdf/.pdf" ? _c("div", {
      staticClass: "pdf",
      staticStyle: {
        "margin-right": "40px"
      }
    }, [index % 2 != 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_green.svg"),
        alt: ""
      }
    }) : _vm._e(), index % 2 == 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_white.svg"),
        alt: ""
      }
    }) : _vm._e(), item.eventPdfLinkEn && item.iid != 41 ? _c("span", {
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.eventPdfLinkEn);
        }
      }
    }, [_vm._v("英文")]) : _vm._e(), item.iid == 41 ? _c("span", {
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.eventPdfLinkEn);
        }
      }
    }, [_vm._v("主旨报告")]) : _vm._e()]) : _vm._e(), item.iid == 41 ? _c("div", {
      staticClass: "pdf"
    }, [index % 2 != 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_green.svg"),
        alt: ""
      }
    }) : _vm._e(), index % 2 == 0 ? _c("img", {
      staticClass: "icon-svg",
      attrs: {
        src: require("../assets/icon/pdf_white.svg"),
        alt: ""
      }
    }) : _vm._e(), _vm.language == 2 ? _c("span", {
      on: {
        click: function ($event) {
          return _vm.viewPdf("https://nuohui-public.oss-cn-hangzhou.aliyuncs.com/officalWebsite/event/Full-text%20transcript.pdf");
        }
      }
    }, [_vm._v("Full-text transcript")]) : _vm._e(), _vm.language != 2 ? _c("span", {
      on: {
        click: function ($event) {
          return _vm.viewPdf2("https://nuohui-public.oss-cn-hangzhou.aliyuncs.com/officalWebsite/event/Full-text%20transcript.pdf");
        }
      }
    }, [_vm._v("全文速记")]) : _vm._e()]) : _vm._e()])]), _c("div", {
      staticClass: "layout-right"
    }, [_c("p", {
      staticClass: "activity-date",
      staticStyle: {
        "font-size": "1em"
      }
    }, [_c("span", [_vm._v(_vm._s(item.month))]), _c("span", [_vm._v("/")]), _c("span", [_vm._v(_vm._s(item.day))])]), _c("p", {
      staticClass: "activity-date",
      staticStyle: {
        "font-size": "1.2em"
      }
    }, [_vm._v(_vm._s(item.year))]), _c("p", {
      staticClass: "activity-date"
    }, [_vm._v(_vm._s(item.time))])])]);
  }), 0)], 2)])])])])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };